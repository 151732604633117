import React, { useState, useEffect, useMemo } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import Select from 'react-select';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { sendGetSettingsData, sendSettingsRequest } from '../../services/setting-services';
import { areObjectsEqual } from '../../utils/utils';
import { SettingsHeader, SettingsListItem, SwitchWithTitle } from '../settings-components';
import './mobile-app-settings.css';

export default function MobileAppSettings() {
  const [isUpdateDisable, setIsUpdateDisable] = useState(true);
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [settingData, setSettingData] = useState(null);
  const [defaultSettingData, setDefaultSettingData] = useState(null);

  const handleTrackingAroundMe = (event) => {
    setSettingData({
      ...settingData,
      tracking: {
        ...settingData.tracking,
        status: event.target.checked ? 'enabled' : 'disabled',
      },
    });
  };

  const handleCoordinates = (event) => {
    setSettingData({
      ...settingData,
      watermark: {
        ...settingData.watermark,
        coordinates: event.target.checked ? 'enabled' : 'disabled',
      },
    });
  };

  const handleDate = (event) => {
    setSettingData({
      ...settingData,
      watermark: {
        ...settingData.watermark,
        date: event.target.checked ? 'enabled' : 'disabled',
      },
    });
  };

  const handleTime = (event) => {
    setSettingData({
      ...settingData,
      watermark: {
        ...settingData.watermark,
        time: event.target.checked ? 'enabled' : 'disabled',
      },
    });
  };

  useEffect(() => {
    getSettingsModule();
  }, []);

  useEffect(() => {
    if (defaultSettingData !== null && settingData !== null) {
      const areEqual = areObjectsEqual(defaultSettingData, settingData);
      setIsUpdateDisable(areEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingData]);

  const colourStyles = {
    control: (styles, { isDisabled, isMulti }) => ({
      ...styles,
      width: isMulti ? '100%' : 120,
      backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
      border: isDisabled ? '' : '1px solid #262261',
      outline: isDisabled ? '' : '1px solid #262261',

      ':hover': {
        ...styles[':hover'],
        backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
        border: isDisabled ? '' : '1px solid #262261',
      },
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: '#ffffff',
      color: '#606060',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: 4,

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#262261',
        color: '#ffffff',
      },
    }),
    menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
    dropdownIndicator: (styles, { selectProps }) => ({
      ...styles,
      color: '#262261',
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',

      ':hover': {
        ...styles[':hover'],
        color: '#262261',
      },
    }),
  };

  /**
   * Function to retrieve settings module data.
   */
  const getSettingsModule = async () => {
    const { data, status } = await sendGetSettingsData();
    if (status === 200) {
      setSettingData(data.data);
      setDefaultSettingData(data.data);
    } else if (status === 401) {
      toast.error(data.msg);
    }
  };

  const validateAndToast = (value, fieldName) => {
    if (!value) {
      toast.error(`${fieldName} value is required.`);
      return false;
    }

    const isNumeric = /^(?:\d+|\d*\.\d+)$/.test(value);
    if (!isNumeric) {
      toast.error(`${fieldName} value should be a valid number.`);
      return false;
    }

    return true;
  };

  const convertToSquareMeters = (value, unit) => {
    const conversionRates = {
      'sq km': 1000000,
      hectare: 10000,
      acre: 4046.86,
    };

    return value * (conversionRates[unit] || 1);
  };

  const validateSettings = (settingData) => {
    const validations = [
      { value: settingData?.tracking?.value, fieldName: 'Tracking distance' },
      { value: settingData?.cropping_distance?.value, fieldName: 'Crop to Crop distance' },
      {
        value: settingData?.['maximum area of polygon']?.values,
        fieldName: 'Maximum area of polygon',
      },
      {
        value: settingData?.['minimum area of polygon']?.values,
        fieldName: 'Minimum area of polygon',
      },
      {
        value: settingData?.polygon_to_polygon_distance?.value,
        fieldName: 'Minimum Marking to Marking Distance',
      },
    ];

    for (const { value, fieldName } of validations) {
      if (!validateAndToast(value, fieldName)) return false;
    }

    return true;
  };

  const onSearch = async (event) => {
    event.preventDefault();

    const minPolygonArea = convertToSquareMeters(
      settingData['minimum area of polygon'].values,
      settingData['minimum area of polygon']['default unit']
    );
    const maxPolygonArea = convertToSquareMeters(
      settingData['maximum area of polygon'].values,
      settingData['maximum area of polygon']['default unit']
    );
    const cropAreaDistance = convertToSquareMeters(
      settingData?.cropping_distance?.value,
      settingData?.cropping_distance['default unit']
    );
    const minMarkingAreaDistance = convertToSquareMeters(
      settingData?.polygon_to_polygon_distance?.value,
      settingData?.polygon_to_polygon_distance['default unit']
    );

    if (minPolygonArea >= maxPolygonArea) {
      toast.error('Minimum area of polygon should be less than the maximum area of polygon.');
      return false;
    }

    if (minMarkingAreaDistance >= cropAreaDistance) {
      toast.error('Crop to Crop distance can’t be shorter than the Marking to Marking distance');
      return false;
    }

    if (!validateSettings(settingData)) return;

    setUpdateSpinner(true);
    const payload = { settings_data: settingData };
    const { data, status } = await sendSettingsRequest(payload);

    if (status === 200) {
      setUpdateSpinner(false);
      getSettingsModule();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  /**
   * ^ Function to handle the selection of the maximum polygon area unit.
   * @param {*} value - The selected value representing the unit for the maximum polygon area
   */
  const onMaxPolygonAreaUnitSelect = (value) => {
    setSettingData({
      ...settingData,
      'maximum area of polygon': {
        ...settingData['maximum area of polygon'],
        'default unit': value.label,
      },
    });
  };

  /**
   * ^ Function to handle the selection of the minimum polygon area unit.
   * @param {*} value - The selected value representing the unit for the minimum polygon area
   */
  const onMinPolygonAreaUnitSelect = (value) => {
    setSettingData({
      ...settingData,
      'minimum area of polygon': {
        ...settingData['minimum area of polygon'],
        'default unit': value.label,
      },
    });
  };

  /**
   * ^ Function to handle the change in the maximum polygon value.
   * @param {*} event - The event object representing the change in the maximum polygon value
   */
  const onMaxPolygonValueChange = (event) => {
    setSettingData({
      ...settingData,
      'maximum area of polygon': {
        ...settingData['maximum area of polygon'],
        values: event.target.value,
      },
    });
  };

  /**
   * ^ Function to handle the change in the minimum polygon value.
   * @param {*} event - The event object representing the change in the minimum polygon value
   */
  const onMinPolygonValueChange = (event) => {
    setSettingData({
      ...settingData,
      'minimum area of polygon': {
        ...settingData['minimum area of polygon'],
        values: event.target.value,
      },
    });
  };

  /**
   * ^ Function to handle the selection of photo resolution.
   * @param {*} resolution - The selected resolution value
   */
  const handlePhotoResolution = (resolution) => {
    setSettingData({
      ...settingData,
      Resolution: {
        ...settingData['Resolution'],
        'default resolution': resolution,
      },
    });
  };

  /**
   * ^ Function to handle the change in the tracking distance value.
   * @param {*} event - The event object representing the change in the tracking distance value
   */
  const onChangeTrackingDistance = (event) => {
    setSettingData({
      ...settingData,
      tracking: {
        ...settingData.tracking,
        value: event.target.value,
      },
    });
  };

  /**
   * ^ Function to handle the selection of the tracking distance unit.
   * @param {*} value - The selected value representing the unit for the tracking distance
   */
  const onTrackingDistanceUnitSelect = (value) => {
    setSettingData({
      ...settingData,
      tracking: {
        ...settingData.tracking,
        'default unit': value.label,
      },
    });
  };

  const onChangeCroppingDistance = (event) => {
    setSettingData({
      ...settingData,
      cropping_distance: {
        ...settingData.cropping_distance,
        value: event.target.value,
      },
    });
  };

  const onCroppingDistanceUnitSelect = (value) => {
    setSettingData({
      ...settingData,
      cropping_distance: {
        ...settingData.cropping_distance,
        'default unit': value.label,
      },
    });
  };

  const onChangePolygonToPolygonDistance = (event) => {
    setSettingData({
      ...settingData,
      polygon_to_polygon_distance: {
        ...settingData.polygon_to_polygon_distance,
        value: event.target.value,
      },
    });
  };

  const onPolygonToPolygonDistanceUnitSelect = (value) => {
    setSettingData({
      ...settingData,
      polygon_to_polygon_distance: {
        ...settingData.polygon_to_polygon_distance,
        'default unit': value.label,
      },
    });
  };

  const onSelectedFieldsChange = (values) => {
    setSettingData({
      ...settingData,
      gt_stat_count_fields: {
        ...settingData.gt_stat_count_fields,
        selected_fields: values,
      },
    });
  };

  const selectedFieldValues = useMemo(
    () =>
      settingData?.gt_stat_count_fields?.fields?.filter((item) =>
        settingData?.gt_stat_count_fields?.selected_fields.find(
          (selectedField) => selectedField.value === item.value
        )
      ) || null,
    [settingData?.gt_stat_count_fields]
  );

  return (
    <>
      <SettingsHeader title="Map & Marking Settings" />

      <section className="mobile-settings-section">
        <SettingsListItem
          title="Tracking Mode"
          description="Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.">
          <SwitchWithTitle
            title="Tracking Around Me"
            isChecked={settingData !== null && settingData.tracking?.status === 'enabled'}
            onChange={handleTrackingAroundMe}
          />
        </SettingsListItem>

        <SettingsListItem
          title="Tracking Distance"
          description="Maximum distance allowed between marking and user’s current location">
          {settingData !== null && (
            <div className="combo-box-wrapper">
              <input
                type="number"
                placeholder="Enter Value"
                className="unit-input"
                value={settingData?.tracking?.value}
                onChange={onChangeTrackingDistance}
                onWheel={(e) => e.target.blur()}
              />
              <Select
                options={[{ label: 'm', value: 'm' }]}
                styles={colourStyles}
                onChange={onTrackingDistanceUnitSelect}
                isSearchable={false}
                value={{
                  value: settingData?.tracking['default unit'],
                  label: settingData?.tracking['default unit'],
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </SettingsListItem>

        <SettingsListItem title="Crop to Crop Distance Threshold">
          {settingData !== null && (
            <div className="combo-box-wrapper">
              <input
                type="number"
                placeholder="Enter Value"
                className="unit-input"
                value={settingData?.cropping_distance?.value}
                onChange={onChangeCroppingDistance}
                onWheel={(e) => e.target.blur()}
              />

              <Select
                options={[{ label: 'm', value: 'm' }]}
                styles={colourStyles}
                onChange={onCroppingDistanceUnitSelect}
                isSearchable={false}
                value={{
                  value: settingData?.cropping_distance['default unit'],
                  label: settingData?.cropping_distance['default unit'],
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </SettingsListItem>

        <SettingsListItem title="Minimum Marking to Marking Distance">
          {settingData !== null && (
            <div className="combo-box-wrapper">
              <input
                type="number"
                placeholder="Enter Value"
                className="unit-input"
                value={settingData?.polygon_to_polygon_distance?.value}
                onChange={onChangePolygonToPolygonDistance}
                onWheel={(e) => e.target.blur()}
              />
              <Select
                options={[{ label: 'm', value: 'm' }]}
                styles={colourStyles}
                onChange={onPolygonToPolygonDistanceUnitSelect}
                isSearchable={false}
                value={{
                  value: settingData?.polygon_to_polygon_distance['default unit'],
                  label: settingData?.polygon_to_polygon_distance['default unit'],
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </SettingsListItem>

        <SettingsListItem
          title="Maximum Area of Polygon"
          description="Maximum area that can be marked as a polygon">
          {settingData !== null && (
            <div className="combo-box-wrapper">
              <input
                type="number"
                placeholder="Enter Value"
                className="unit-input"
                value={settingData['maximum area of polygon'].values}
                onChange={onMaxPolygonValueChange}
                onWheel={(e) => e.target.blur()}
              />
              <Select
                options={settingData['maximum area of polygon'].units.map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                styles={colourStyles}
                onChange={onMaxPolygonAreaUnitSelect}
                isSearchable={false}
                value={{
                  value: settingData['maximum area of polygon']['default unit'],
                  label: settingData['maximum area of polygon']['default unit'],
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </SettingsListItem>

        <SettingsListItem
          title="Minimum Area of Polygon"
          description="Minimum area that can be marking as a polygon">
          {settingData !== null && (
            <div className="combo-box-wrapper">
              <input
                type="number"
                placeholder="Enter Value"
                className="unit-input"
                value={settingData['minimum area of polygon'].values}
                onChange={onMinPolygonValueChange}
                onWheel={(e) => e.target.blur()}
              />
              <Select
                options={settingData['minimum area of polygon'].units.map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                styles={colourStyles}
                onChange={onMinPolygonAreaUnitSelect}
                isSearchable={false}
                value={{
                  value: settingData['minimum area of polygon']['default unit'],
                  label: settingData['minimum area of polygon']['default unit'],
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </SettingsListItem>

        <SettingsListItem title="Selected Fields">
          {settingData !== null && (
            <div className="combo-box-wrapper">
              <Select
                isMulti
                options={settingData?.gt_stat_count_fields?.fields || []}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                onChange={onSelectedFieldsChange}
                allowSelectAll={false}
                value={selectedFieldValues}
                styles={{ ...colourStyles }}
                placeholder={'Select Fields'}
              />
            </div>
          )}
        </SettingsListItem>

        <SettingsListItem
          title="Water Mark"
          description="Enable and Disable the watermark of co-ordintes, date & time on Images captured by the mobile app users Enable and Disable the watermark of co-ordintes, date & time on Images captured by the mobile app users">
          <SwitchWithTitle
            title="Co-ordinates"
            isChecked={settingData !== null && settingData.watermark.coordinates === 'enabled'}
            onChange={handleCoordinates}
          />
          <SwitchWithTitle
            title="Date"
            isChecked={settingData !== null && settingData.watermark.date === 'enabled'}
            onChange={handleDate}
          />
          <SwitchWithTitle
            title="Time"
            isChecked={settingData !== null && settingData.watermark.time === 'enabled'}
            onChange={handleTime}
          />
        </SettingsListItem>

        <SettingsListItem
          title="Resolution of the Images Captured"
          description="Select any resolution to capture the image via mobile phone">
          {settingData !== null && (
            <ButtonGroup className="button-group">
              {settingData['Resolution'].values.map((buttonLabel, index) => (
                <Button
                  key={buttonLabel}
                  className={`${
                    buttonLabel === settingData.Resolution['default resolution']
                      ? 'selected-button'
                      : ''
                  }`}
                  onClick={() => handlePhotoResolution(buttonLabel)}>
                  {buttonLabel}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </SettingsListItem>

        <div className="update-password-button-container">
          <Button
            variant={'contained'}
            onClick={onSearch}
            className="update-button"
            disabled={isUpdateDisable}>
            <strong>Update</strong>
            {updateSpinner && (
              <CircularProgress
                size={18}
                sx={{
                  color: '#fff',
                  margin: '0px 10px',
                }}
                color="secondary"
              />
            )}
          </Button>
        </div>
      </section>
    </>
  );
}
